.store-locator-page {
    margin-top: -110px; //offset for header
    padding: 170px 0 60px;
    position: relative;

    @media (max-width:$medium-media-query-size) {
        padding: 150px 10px 60px 10px;

    }



    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, $green-light , $teal);
        z-index: -1;
    }
    &:after {
        background: url('../imgs/bg-pattern-white.png');
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;

    }
    &-inner {
        max-width: $max-width;
        margin: 0 auto;
        position: relative;
        z-index: 100;

        h1 {
            color: #fff;
        }
    }
}

.store-locator-page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    
    @media (max-width:$medium-media-query-size) {
        flex-direction: column;
    }

    .amazon-fresh {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        align-items: center;

        h4 {
            color: #fff;
            position: relative;
            svg {
position: absolute;
    fill: #fff;
    width: 36px;
    height: 54px;
    right: 30px;
    top: 48px;
    transform: rotate(200deg);
            }  
        }

        img {
            width: 140px;
        }
    }
}


#wpsl-gmap {
    border-radius: 20px;

}

#wpsl-stores {
    padding: 20px; 
    background:rgba(#fff, 0.8);
    border-radius: 20px;

    > li {
        border-bottom: 1px solid $green-dark;
    }

    .wpsl-store-location {
        p:not(.wpsl-contact-details) {
            strong {
                font-weight: $bold;
                font-size: 1.4rem;
                color: $green-light;
                margin-bottom: 10px;
            }
        }


        p {
            margin-bottom: 5px;
            color: $green-dark;
        }

        .wpsl-contact-details {
            color: $green-dark;

        }
    }
}

.products-wrapper {
    padding: 10px;
    border-radius: 10px;
    background: rgba($teal-light, 0.5);
    margin: 10px 0;
    font-weight: $bold;

    p {
        color: $green-dark;
    }

    > p {
        position: relative;
        cursor: pointer;
        &:after {
            content: '>';
            content: '>';
            position: absolute;
            top: 0;
            right: 20px;
            color: #006054;
            transition: all 0.2s ease-in;
            font-weight: 600;
            transform: rotate(90deg);
            font-family: serif;
            @include transition(.2s);

        }

        &.active {
            &:after {
                transform: rotate(270deg);
            }

            + .store-products {
                max-height: 200px;
            }
        }
    }


}

.store-products {
    max-height: 0;
    overflow-y: scroll;
    @include transition(.2s);

    a {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        
        h4 {
            margin-left: 10px;
            color: $green-dark;
        }

        img {
            width: 90px;
        }

    }
}

#wpsl-wrap {
    .wpsl-filter {
        background:rgba(#fff, 0.8);
        border-radius: 20px;
        padding: 10px;
        @media (max-width:$medium-media-query-size) {
            padding:20px;
        }
        #wpsl-search-wrap {
            form {
                display: flex;
                align-items: center;

                @media (max-width:$medium-media-query-size) {
                    flex-direction: column;
                }

                .wpsl-input div {
                    width: unset;
                    flex-grow: 0;
                }

                label {
                    color: $green-dark;
                    font-weight: $bold;
                    text-transform: uppercase;
                    font-size: .85rem;
                }

                .wpsl-input {
                    width: calc(37.5% - 30px);
                    flex-grow: 1;
                }

                .wpsl-select-wrap {
                    width: calc(25% - 30px);
                    flex-grow: 1;

                }

                #wpsl-category {
                    width: calc(37.5% - 30px);
                    flex-grow: 1;

                }

                #wpsl-search-input {
                    width: unset;
                    flex-grow: 1;
                }
                .wpsl-select-wrap {
                    margin-bottom: 0;
                    @media (max-width:$medium-media-query-size) {
                        width: 100%;
                    }

                }

                .wpsl-input, #wpsl-radius, #wpsl-category {
                    display: flex;
                    margin-right: 20px;
                    align-items: center;
                    margin-bottom: 0;

                    @media (max-width:$medium-media-query-size) {
                        width: 100%;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-right: 0;

                        .wpsl-search-input#wpsl-search-input {
                            width: 100% !important;
                        }

                        > * {
                            width: 100% !important;
                        }
                    }
                }
                
                .wpsl-search-btn-wrap {
                    flex-grow: 0;
                    width: 90px;
                    margin: 0 0 0 0 !important;
                    @media (max-width:$medium-media-query-size) {
                        margin: 10px 0 0 0 !important;

                    }

                    #wpsl-search-btn {
                        @extend .btn;
                    }
                }

            }
        }
    }
}



#wpsl-wrap,
.wpsl-gmap-canvas {
    margin-bottom: 20px;
    width: 100%
}

#wpsl-result-list a,
#wpsl-wrap [class*=" wpsl-icon-"]:focus,
#wpsl-wrap [class^=wpsl-icon-]:active {
    outline: 0
}

#wpsl-map-controls div:hover,
#wpsl-reset-map:hover,
#wpsl-search-btn:hover,
.wpsl-dropdown {
    cursor: pointer
}

#wpsl-wrap,
.wpsl-clearfix:after,
.wpsl-contact-details {
    clear: both
}


#wpsl-gmap {
    float: right;
    width: 66.5%;
    height: 350px;
    margin-bottom: 0
}

.wpsl-store-below #wpsl-gmap {
    float: none;
    width: 100%
}

.wpsl-gmap-canvas {
    height: 300px
}

#wpsl-gmap div:not[class^=gv-iv],
#wpsl-gmap img,
.wpsl-gmap-canvas div:not[class^=gv-iv],
.wpsl-gmap-canvas img {
    box-shadow: none!important;
    max-width: none!important;
    background: 0 0
}

#wpsl-gmap img,
.wpsl-gmap-canvas img {
    display: inline!important;
    opacity: 1!important;
    max-height: none!important
}

#wpsl-gmap * {
    box-sizing: content-box!important;
    -webkit-box-sizing: content-box!important;
    -moz-box-sizing: content-box!important
}

#wpsl-gmap div.gm-iv-marker,
.wpsl-gmap-canvas div.gm-iv-marker {
    backgroud-image: inherit
}

#wpsl-wrap {
    position: relative;
    overflow: hidden
}

#wpsl-search-wrap {
    float: left;
    width: 100%
}

#wpsl-search-wrap form {
    margin: 0;
    padding: 0;
    border: none;
    outline: 0
}

#wpsl-gmap #wpsl-map-controls {
    position: absolute;
    height: 28px;
    right: 10px;
    bottom: 24px;
    border-radius: 2px;
    z-index: 3;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden
}

#wpsl-gmap #wpsl-map-controls.wpsl-street-view-exists {
    right: 48px
}

#wpsl-map-controls .wpsl-direction-preloader {
    margin: 5px 5px 0
}

#wpsl-map-controls div {
    float: left;
    background: #fff;
    border-radius: 2px
}

#wpsl-wrap [class*=" wpsl-icon-"],
#wpsl-wrap [class^=wpsl-icon-] {
    position: relative;
    float: left;
    padding: 7px 9px 7px 8px;
    display: inline-block;
    font-family: wpsl-fontello;
    font-style: normal;
    font-weight: 400;
    font-size: 1.3em;
    color: #737373;
    speak: none;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

#wpsl-map-controls span {
    font-family: inherit;
    font-size: inherit
}

#wpsl-wrap .wpsl-ie [class*=" wpsl-icon-"],
#wpsl-wrap .wpsl-ie [class^=wpsl-icon-] {
    padding: 9px 8px 4px
}

#wpsl-wrap.wpsl-mobile [class*=" wpsl-icon-"],
#wpsl-wrap.wpsl-mobile [class^=wpsl-icon-] {
    padding: 8px 10px
}

#wpsl-wrap .wpsl-icon-reset {
    border-radius: 2px 0 0 2px;
    z-index: 2;
    padding-left: 9px;
    padding-right: 4px
}

#wpsl-wrap .wpsl-icon-direction {
    z-index: 1
}

#wpsl-map-controls.wpsl-reset-exists .wpsl-icon-direction {
    border-radius: 0 2px 2px 0
}

#wpsl-wrap .wpsl-active-icon,
#wpsl-wrap [class*=" wpsl-icon-"]:hover,
#wpsl-wrap [class^=wpsl-icon-]:hover {
    color: #000
}

#wpsl-wrap .wpsl-in-progress,
#wpsl-wrap .wpsl-in-progress:hover {
    color: #c6c6c6
}

#wpsl-gmap #wpsl-reset-map {
    position: absolute;
    display: none;
    right: 37px;
    top: 37px;
    padding: 6px 14px;
    background: #fff!important;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 2px;
    z-index: 3
}

.gm-style-cc {
    word-wrap: normal
}

#wpsl-search-wrap .wpsl-input,
#wpsl-search-wrap .wpsl-select-wrap {
    display: table
}

#wpsl-search-btn,
#wpsl-search-wrap #wpsl-radius,
#wpsl-search-wrap #wpsl-results,
#wpsl-search-wrap .wpsl-input input,
#wpsl-search-wrap .wpsl-input label {
    display: table-cell
}

#wpsl-search-wrap label {
    margin-bottom: 0
}

#wpsl-search-input {
    height: auto;
    padding: 7px 12px;
    font-size: 100%;
    margin: 0
}

#wpsl-search-btn,
#wpsl-search-wrap input {
    border: 1px solid #d2d2d2;
    border-radius: 3px
}

#wpsl-search-btn {
    padding: 7px 10px;
    line-height: 1.428571429;
    font-weight: 400;
    color: #7c7c7c;
    background-color: #e6e6e6;
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(top, #f4f4f4, #e6e6e6);
    background-image: -ms-linear-gradient(top, #f4f4f4, #e6e6e6);
    background-image: -webkit-linear-gradient(top, #f4f4f4, #e6e6e6);
    background-image: -o-linear-gradient(top, #f4f4f4, #e6e6e6);
    background-image: linear-gradient(top, #f4f4f4, #e6e6e6);
    box-shadow: 0 1px 2px rgba(64, 64, 64, .1);
    text-transform: none!important
}

#wpsl-search-input.wpsl-error {
    border: 1px solid #bd0028!important
}

.wpsl-search {
    margin-bottom: 12px;
    padding: 12px 12px 0;
    background: #f4f3f3
}

.wpsl-search.wpsl-checkboxes-enabled {
    padding: 12px
}

.wpsl-back {
    display: inline-block
}

#wpsl-result-list {
    width: 33%;
    margin-right: .5%
}

.wpsl-store-below #wpsl-result-list {
    width: 100%;
    margin: 12px 0 0
}

#wpsl-direction-details,
#wpsl-stores {
    height: 350px;
    overflow-y: auto
}

#wpsl-direction-details,
.wpsl-hide {
    display: none
}

#wpsl-result-list p {
    padding-left: 10px
}

.wpsl-store-below #wpsl-result-list p {
    padding-left: 0
}

.wpsl-direction-before {
    margin: 14px 0 21px;
    padding-left: 10px
}

.wpsl-store-below .wpsl-direction-before {
    padding-left: 0
}

.wpsl-direction-before div {
    margin-top: 10px
}

#wpsl-wrap #wpsl-result-list li {
    padding: 10px;
    border-bottom: 1px dotted #ccc;
    margin-left: 0;
    overflow: hidden;
    list-style: none!important;
    text-indent: 0
}

#wpsl-wrap #wpsl-result-list li li {
    padding: 0;
    border-bottom: 0;
    margin-left: 14px;
    overflow: visible
}

#wpsl-wrap #wpsl-result-list ul li {
    list-style: none!important
}

#wpsl-wrap #wpsl-result-list ol li {
    list-style: decimal!important
}

#wpsl-wrap.wpsl-store-below #wpsl-result-list li {
    padding: 10px 10px 10px 0
}

#wpsl-result-list li p {
    padding-left: 0;
    margin: 0 0 20px
}

.wpsl-store-details.wpsl-store-listing {
    position: relative;
    padding-right: 20px
}

.wpsl-store-details.wpsl-store-listing.wpsl-active-details:before,
.wpsl-store-details.wpsl-store-listing:before {
    position: absolute;
    content: '';
    bottom: 6px;
    right: 0;
    border-top: 5px solid #000;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent
}

.wpsl-store-details.wpsl-store-listing.wpsl-active-details:before {
    border-bottom: 5px solid #000;
    border-top: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent
}

#wpsl-stores .wpsl-store-thumb {
    float: right;
    border-radius: 3px;
    margin: 7px 0 0 10px;
    padding: 0;
    border: none
}

.wpsl-direction-index {
    float: left;
    width: 8%;
    margin: 0 5% 0 0
}

.wpsl-direction-txt {
    float: left;
    width: 62%
}

.wpsl-direction-distance {
    float: left;
    width: 20%;
    margin: 0 0 0 5%
}

.wpsl-direction-txt span {
    display: block;
    margin-top: 10px
}

.wpsl-country,
.wpsl-street {
    display: block;
    border-bottom: none!important
}

.wpsl-directions {
    display: table;
    border-bottom: none!important
}

#wpsl-wrap #wpsl-result-list li.wpsl-preloader {
    position: relative;
    border-bottom: none;
    padding: 10px 10px 10px 35px
}

.wpsl-preloader img {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -8px;
    box-shadow: none!important;
    border: none!important
}

.wpsl-preloader span {
    float: left;
    margin: -5px 0 0 11px
}

#wpsl-search-btn,
#wpsl-search-wrap div {
    margin-right: 10px;
    flex-grow: 1;
}

#wpsl-search-wrap .wpsl-select-wrap {
    position: relative;
    z-index: 2;
    margin-right: 0
}

#wpsl-search-wrap .wpsl-input-field {
    position: relative
}

#wpsl-radius,
#wpsl-results {
}

#wpsl-category {
    z-index: 1;
    clear: both
}

#wpsl-search-wrap .wpsl-dropdown div {
    position: absolute;
    float: none;
    margin: -1px 0 0;
    top: 100%;
    left: -1px;
    right: -1px;
    border: 1px solid #ccc;
    background: #fff;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -ms-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out
}

#wpsl-search-wrap .wpsl-dropdown.wpsl-active div {
    opacity: 1
}

#wpsl-search-wrap .wpsl-input label {
    margin-right: 0
}

#wpsl-radius {
    margin-right: 10px
}

#wpsl-search select,
#wpsl-search-wrap select,
.wpsl-direction-details {
    display: none
}

#wpsl-results label {
    width: auto
}

#wpsl-result-list ul {
    list-style: none;
    margin: 0;
    padding: 0
}

#wpsl-gmap .wpsl-info-window,
.wpsl-gmap-canvas .wpsl-info-window {
    max-width: 225px
}

.wpsl-info-window span,
.wpsl-more-info-listings span {
    display: block
}

.wpsl-info-window .wpsl-no-margin {
    margin: 0
}

.wpsl-more-info-listings {
    display: none
}

.wpsl-info-window span span {
    display: inline!important
}

#wpsl-wrap .wpsl-info-window p {
    margin: 0 0 10px
}

.wpsl-store-hours {
    margin-top: 10px
}

.wpsl-store-hours strong {
    display: block
}

#wpsl-gmap .wpsl-info-actions {
    display: block;
    margin: 10px 0!important
}

.wpsl-info-actions a {
    float: left;
    margin-right: 7px
}

.wpsl-info-actions .wpsl-zoom-here {
    margin-right: 0
}

.wpsl-dropdown {
    position: relative;
    width: 90px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    margin-right: 0!important;
    z-index: 2
}

.wpsl-dropdown ul {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0!important;
    margin: 0!important;
    list-style: none;
    overflow: hidden
}

.wpsl-dropdown:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, .15)
}

.wpsl-dropdown .wpsl-selected-item,
.wpsl-dropdown li {
    position: relative;
    display: block;
    line-height: normal;
    color: #000;
    overflow: hidden
}

#wpsl-radius .wpsl-dropdown .wpsl-selected-item,
#wpsl-radius .wpsl-dropdown li,
#wpsl-results .wpsl-dropdown .wpsl-selected-item,
#wpsl-results .wpsl-dropdown li {
    white-space: nowrap
}

.wpsl-selected-item:after {
    position: absolute;
    content: "";
    right: 12px;
    top: 50%;
    margin-top: -4px;
    border: 6px solid transparent;
    border-top: 8px solid #000
}

.wpsl-active .wpsl-selected-item:after {
    margin-top: -10px;
    border: 6px solid transparent;
    border-bottom: 8px solid #000
}

.wpsl-dropdown li:hover {
    background: #f8f9f8;
    position: relative;
    z-index: 3;
    color: #000
}

.wpsl-dropdown .wpsl-selected-item,
.wpsl-dropdown li,
.wpsl-selected-item {
    list-style: none;
    padding: 9px 12px!important;
    margin: 0!important
}

.wpsl-selected-dropdown {
    font-weight: 700
}

.wpsl-clearfix:after,
.wpsl-clearfix:before {
    content: " ";
    display: table
}

#wpsl-wrap .wpsl-selected-item {
    position: static;
    padding-right: 35px!important
}

#wpsl-category,
.wpsl-input,
.wpsl-select-wrap {
    position: relative;
    margin-bottom: 10px
}

#wpsl-search-wrap .wpsl-scroll-required div {
    overflow-y: scroll
}

.wpsl-scroll-required ul {
    overflow: visible
}

.wpsl-provided-by {
    float: right;
    padding: 5px 0;
    text-align: right;
    font-size: 12px;
    width: 100%
}

#wpsl-wrap .wpsl-results-only label {
    width: auto
}

.wpsl-contact-details,
.wpsl-location-address,
.wpsl-locations-details {
    margin-bottom: 15px
}

table.wpsl-opening-hours td {
    vertical-align: top;
    padding: 0 15px 0 0;
    text-align: left
}

table.wpsl-opening-hours time {
    display: block
}

table.wpsl-opening-hours {
    width: auto!important;
    font-size: 100%!important
}

table.wpsl-opening-hours,
table.wpsl-opening-hours td {
    border: none!important
}

.wpsl-gmap-canvas .wpsl-infobox {
    min-width: 155px;
    max-width: 350px!important;
    padding: 10px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 300;
    border: 1px solid #ccc;
    background: #fff!important
}

.wpsl-gmap-canvas .wpsl-infobox:after,
.wpsl-gmap-canvas .wpsl-infobox:before {
    position: absolute;
    content: "";
    left: 40px;
    bottom: -11px
}

.wpsl-gmap-canvas .wpsl-infobox:after {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid #fff
}

.wpsl-gmap-canvas .wpsl-infobox:before {
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid #ccc;
    bottom: -13px;
    left: 38px
}

#wpsl-checkbox-filter,
.wpsl-custom-checkboxes {
    display: block;
    float: left;
    margin: 5px 0 15px;
    padding: 0;
    width: 100%
}

#wpsl-checkbox-filter li,
.wpsl-custom-checkboxes li {
    float: left;
    list-style: none;
    margin: 0 1% 0 0
}

#wpsl-checkbox-filter.wpsl-checkbox-1-columns li,
.wpsl-custom-checkboxes.wpsl-checkbox-1-columns li {
    width: 99%
}

#wpsl-checkbox-filter.wpsl-checkbox-2-columns li,
.wpsl-custom-checkboxes.wpsl-checkbox-2-columns li {
    width: 49%
}

#wpsl-checkbox-filter.wpsl-checkbox-3-columns li,
.wpsl-custom-checkboxes.wpsl-checkbox-3-columns li {
    width: 32%
}

#wpsl-checkbox-filter.wpsl-checkbox-4-columns li,
.wpsl-custom-checkboxes.wpsl-checkbox-4-columns li {
    width: 24%
}

#wpsl-checkbox-filter input,
.wpsl-custom-checkboxes input {
    margin-right: 5px
}

#wpsl-result-list .wpsl-contact-details span {
    display: block!important
}

#wpsl-search-wrap .select2 {
    display: none!important
}

.rtl #wpsl-result-list {
    float: left
}

.rtl #wpsl-checkbox-filter input,
.rtl .wpsl-custom-checkboxes input {
    margin-right: 0;
    margin-left: 5px
}

.rtl .wpsl-info-actions a {
    float: right;
    margin: 0 0 0 7px
}

.rtl #wpsl-gmap .wpsl-info-window {
    padding-right: 22px
}

.rtl #wpsl-wrap #wpsl-result-list li.wpsl-preloader {
    padding: 10px 35px 10px 0
}

.rtl .wpsl-preloader img {
    left: 0;
    right: 10px
}

.wpsl-twentynineteen .wpsl-input {
    width: 100%
}

.wpsl-twentynineteen #wpsl-search-input {
    line-height: 1.3em
}

.wpsl-twentynineteen #wpsl-search-wrap label {
    margin-top: 6px
}

.wpsl-twentynineteen .wpsl-dropdown {
    width: 116px
}

#wpsl-results .wpsl-dropdown {
    width: 81px
}

@media (max-width:825px) {
    #wpsl-search-input {
        width: 348px
    }
    .wpsl-results-only #wpsl-search-wrap .wpsl-dropdown {
        width: 70px
    }
    #wpsl-search-wrap .wpsl-input {
        width: 100%;
        margin-bottom: 10px
    }
    #wpsl-category label,
    #wpsl-radius label,
    .wpsl-cat-results-filter #wpsl-search-wrap .wpsl-input,
    .wpsl-input label,
    .wpsl-no-filters #wpsl-search-wrap .wpsl-input,
    .wpsl-results-only #wpsl-search-wrap .wpsl-input {
        width: auto
    }
}

@media (max-width:720px) {
    #wpsl-search-wrap .wpsl-dropdown {
        width: 114px
    }
}

@media (max-width:675px) {
    #wpsl-search-wrap #wpsl-search-btn {
        float: left;
        margin: 0 5px 0 0
    }
    .wpsl-dropdown,
    .wpsl-results-only #wpsl-search-wrap .wpsl-input {
        width: 100%
    }
    .wpsl-search {
        padding: 2%
    }
    #wpsl-result-list p,
    #wpsl-wrap #wpsl-result-list li,
    .wpsl-direction-before {
        padding-left: 0
    }
    .wpsl-input {
        margin-right: 0
    }
    #wpsl-gmap,
    #wpsl-result-list {
        float: none;
        width: 100%
    }
    #wpsl-gmap {
        margin-bottom: 15px;
        margin-top: 10px
    }
    #wpsl-result-list,
    .wpsl-cat-results-filter .wpsl-select-wrap,
    .wpsl-filter .wpsl-select-wrap {
        margin-bottom: 10px
    }
    #wpsl-wrap #wpsl-result-list li.wpsl-preloader {
        padding-left: 25px
    }
    .wpsl-preloader img {
        left: 0
    }
    #wpsl-stores.wpsl-not-loaded {
        height: 25px
    }
    #wpsl-reset-map {
        top: 25px
    }
    #wpsl-category,
    #wpsl-search-btn,
    .wpsl-input,
    .wpsl-no-filters #wpsl-search-wrap .wpsl-input,
    .wpsl-select-wrap {
        margin-bottom: 0
    }
    #wpsl-stores.wpsl-no-autoload {
        height: auto!important
    }
    #wpsl-checkbox-filter.wpsl-checkbox-3-columns li,
    #wpsl-checkbox-filter.wpsl-checkbox-4-columns li {
        width: 49%
    }
}

@media (max-width:570px) {
    #wpsl-search-wrap #wpsl-search-btn {
        margin-bottom: 5px
    }
    .wpsl-search {
        padding: 4%
    }
    #wpsl-search-input {
        width: 98%!important
    }
    .wpsl-cat-results-filter #wpsl-search-input,
    .wpsl-cat-results-filter #wpsl-search-wrap .wpsl-input,
    .wpsl-no-results #wpsl-search-input,
    .wpsl-results-only #wpsl-search-input {
        width: 100%!important
    }
    .wpsl-search-btn-wrap {
        margin-top: 15px;
        clear: both
    }
    .wpsl-checkboxes-enabled .wpsl-search-btn-wrap {
        margin-top: 0
    }
    #wpsl-search-btn,
    #wpsl-search-wrap div {
        margin-right: 0
    }
    #wpsl-search-wrap div label {
        display: block;
        width: 100%
    }
    .wpsl-select-wrap {
        width: 100%
    }
    #wpsl-radius,
    #wpsl-results {
        width: 50%
    }
    #wpsl-radius {
        margin-right: 4%
    }
    #wpsl-search-wrap .wpsl-dropdown {
        width: 96%!important
    }
    .wpsl-no-filters #wpsl-search-input,
    .wpsl-no-filters #wpsl-search-wrap .wpsl-input {
        width: 100%!important
    }
}

@media (max-width:420px) {
    #wpsl-checkbox-filter li {
        margin: 0
    }
    #wpsl-checkbox-filter.wpsl-checkbox-1-columns li,
    #wpsl-checkbox-filter.wpsl-checkbox-2-columns li,
    #wpsl-checkbox-filter.wpsl-checkbox-3-columns li,
    #wpsl-checkbox-filter.wpsl-checkbox-4-columns li {
        width: 100%
    }
}