.faq {
    h1 { 
        color: $teal;
        
    }


}

.faq-group {
    margin: 30px 0;

    h2 {
        color: $green-light;
    }

    + .faq-group {
        border-top: 3px dashed $green-dark;
        padding-top: 30px;
    }
}

.faq-group-list {


    &-item {
        padding: 10px;
        border-radius: 10px;
        background: rgba($teal-light, 0.6);
        
        + * {
            margin-top: 10px;
        }
    }

    h3 {
        color: $green-dark;
        margin-bottom: 5px;
    }

    p {
        margin: 0 0 0 10px;
        color: $green-dark;

    }
}