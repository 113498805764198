.contact {
    display: flex;
    padding: 60px 0;

    @media (max-width:$medium-media-query-size) {
        flex-direction: column;

        > * {
            width: 100% !important;
            margin-right: 0;

            + * {
                margin-top: 30px;
            }
        }
    }

    &-info {
        width: 30%;
        margin-right: 30px;
        flex-shrink: 0;
    }

    &-form {
        flex-grow: 1;

    }
}

.contact-info-item {
    display: flex;

    &:hover {
        text-decoration: none;
    }

    &-link {
        transform: scale(1);

        &:hover {
            transform: scale(1.05);
        }
    
    }

    + * {
        margin-top: 60px;
    }

    &-icon {
        width: 50px;
        margin-right: 20px;
        flex-shrink: 0;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $teal;
        border-radius: 50px;

        svg {
            width: 20px;
            height: 20px;;
            fill: #fff;
        }

    }

    &-text {
        flex-grow: 1;
        color: $gray-dark;

        &-label {
            font-weight: $bold;
            margin-bottom: 10px;
        }

        &-phone {
            margin-bottom: 0;
            font-size: 1.875rem;
            color:  $green-light;
        }

        &-regular {
            margin-bottom: 0;
            line-height: 1.5rem;

        }
    }
    
}

.contact-form {
    padding: 30px;
    background: rgba($teal-light, 0.75);
    border-radius: 30px;

    &-item {
        + * {
            margin-top: 30px;
        }

        display: flex;
        flex-direction: column;

        label {
            &.label-title {
                font-size: 2.25rem;
                line-height: 2.7rem;
                font-family: $font-heading;
                font-weight: $normal;
                margin-bottom: 1rem;
                text-transform: uppercase;
                color: $green-dark;
                text-align: center;
                @media (max-width:$medium-media-query-size) {
                  font-size: 1.75rem;
                  line-height: 2.25rem;
                }
            }

            color: $green-dark;
            font-size: 1rem;

        }

        input, textarea {
            padding: 10px;
            border-radius: 15px;
            border: 1px solid transparent;
            color: $green-dark !important;
            line-height: 1.5em;
            font-size: 1rem;
            width: 100%;
            &:hover, &:focus, &:blur {
                border-color: $green-dark !important;
            }
            @include transition(.2s);

            &:hover, &:focus {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
            }
        
        }

        textarea {
            min-height: 200px;
        }

        input[type="submit"] {
            @extend .btn-secondary;
            color: #fff !important;
        }

        select {
                              /* styling */
  display: inline-block;
  line-height: 1.5em;
  padding: 10px;
  border-radius: 15px;
  width: 100%;

  background: #fff;
border:none;
font-size: 1rem;
color: $green-dark;
  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, $green-dark 50%),
    linear-gradient(135deg, $green-dark 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 12px),
    calc(100% - 15px) calc(1em + 12px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  @include transition(.2s);

  &:hover, &:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }

        }
    }
}


// errors
.wpcf7-not-valid-tip {
    color: red;
    margin: 5px;
    display: block;
}

.wpcf7-validation-errors {
    display: block;
    padding: 10px;
    border: 1px solid red;
    color: red;
    margin: 10px 0;
}

.wpcf7-mail-sent-ok {
    display: block;
    padding: 10px;
    border: 1px solid $green-dark;
    color: $green-dark;
    margin: 10px 0;
}

.screen-reader-response {
    display: none;
}

.wpcf7-submit {
    background: $green-light !important;

    &:hover {
        background: darken($green-light, 5%) !important;
    }
}