.story-page {
    padding: 60px 0 60px;

    &-inner {
        max-width: $max-width;
        margin: 0 auto;
        display: flex;
        color: $green-dark;

        > * {
            width: calc(50% - 20px);

            + * {
                margin-left: 40px;
            }
        }

        @media (max-width:$medium-media-query-size) {
          padding-left: 20px;
          padding-right: 20px;
          flex-direction: column;
          > * {
            width: 100%;

            + * {
                margin-left: 0;
                margin-top: 40px;
            }
        }
      }

      img {
          width: 100%;
      }
}   
}

.wave-footer-img {

    height: 0;
    padding-top: 87.26px / 1365.42px * 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: $teal;
    }
}

.wave-footer-tag {
    background: $teal;
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: flex-end;

    svg {
        fill: #fff;
        opacity: .5;
        width:700px;
    }
}


// team

.team-page {
    padding: 60px 0 60px;

    &-inner {
        max-width: $max-width;
        margin: 0 auto;
        color: $green-dark;

        @media (max-width:$medium-media-query-size) {
          padding-left: 20px;
          padding-right: 20px;
          flex-direction: column;
          > * {
            width: 100%;

            + * {
                margin-left: 0;
                margin-top: 40px;
            }
        }
      }

      .team-list {
          &-item {
              display: flex;
              @media (max-width:$medium-media-query-size) {
                flex-direction: column;
            }

            + li {
                margin-top: 40px;
            }
              &-image {
                  width: 250px;
                  margin: 0 40px 40px 0;
                  flex-shrink: 0;

                  img {
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                  }
              }

              &-text {
                  &-name {
                    font-size: 2.5rem;
                    color: $green-light;
                    margin-bottom: 10px
                  }

                  &-title {
                    font-size: 1.5rem;
                    color: $green-dark;
                    font-family: $font-heading;
                    margin-bottom: 30px;

                  }

                  p {

                  }
              }
          }
      }
    }   
}
