.max-width {
  width: $max-width;
  margin: 0 auto;

  @media (max-width:$large-media-query-size) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

body {
  background: url('../imgs/bg-pattern.png');
  background-position: top center;
  background-repeat: repeat-y;
  padding-top: 110px;
  
}


.page-pattern {
  margin-top: -110px; //offset for header
  padding: 170px 0 60px;
  @media (max-width:$medium-media-query-size) {
    padding: 130px 0 60px;

  }
  &-inner {
      max-width: $max-width;
      margin: 0 auto;
        @media (max-width:$medium-media-query-size) {
        padding-left: 20px;
        padding-right: 20px;
    }

  }
}