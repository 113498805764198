.recipes-page-title {
    color: $teal;
    margin-bottom: 40px;
    font-size: 4rem;
    line-height: 4.75rem;
    font-family: $font-heading;
    font-weight: $normal;
    text-transform: uppercase;
    
    @media (max-width:$medium-media-query-size) {
      font-size: 3.75rem;
      line-height: 4.5rem;
    }

    span:not(.regular-font) {
        color: $green-light;
    }
}

.recipes-page {
    .certified-icons {
        margin-top: 20px;
        svg {
            height: 60px;
            width: 100px;
        }


        @media (max-width:$medium-media-query-size) {
            display: flex;
            justify-content: center;
        }
    }
}

.recipes-page-images {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width:$medium-media-query-size) {
        flex-direction: column;
    }

    &-products {
        img {
            max-width: 210px;
            @media (max-width:$medium-media-query-size) {
            }
        }
    }

    &-ingredients {
        padding-left: 50px;
        margin-left: 50px;
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 500px;
        flex-wrap: wrap;
        align-items: center;
        flex-grow: 1;

        @media (max-width:$medium-media-query-size) {
            flex-direction: row;
            justify-content: center;
            max-height: unset;
        }

        &:before {
            content: '+';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -30px;
            color: $green-dark;
            font-size: 100px;
            font-weight: bold;
            width: 60px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;        
            @media (max-width:$medium-media-query-size) {
                left: 0;
                right: 0;
                bottom: unset;
                top: -30px;
                width: 100%;
                height: 60px;
            }
        }

        img {
            max-width: 100px;
            height: auto;
        }

        @media (max-width:$medium-media-query-size) {
            padding-left: 0;
            margin-left: 0;
            padding-top: 50px;
            margin-top: 50px;
    
    
        }


    }

    &-final {
        padding-left: 50px;
        margin-left: 50px;
        position: relative;

        &:before {
            content: '=';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -30px;
            color: $green-dark;
            font-size: 100px;
            font-weight: bold;
            width: 60px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;      
            @media (max-width:$medium-media-query-size) {
                left: 0;
                right: 0;
                bottom: unset;
                top: -30px;
                width: 100%;
                height: 60px;
            }

        }

        img {
            max-width: 400px;
            border-radius: 20px;
            @media (max-width:$small-media-query-size) {
                max-width: 100%;

            }
        }

        @media (max-width:$medium-media-query-size) {
            padding-left: 0;
            margin-left: 0;
            padding-top: 50px;
            margin-top: 50px;
    
    
        }

    }
}

.recipes-details {
    &-top-bg {
        height: 0;
        overflow: hidden;
        padding-top: 87.26px / 1365.42px * 100%;
        position: relative;
      
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            fill: $teal;
        }
    }

    &-inner {
        background: $teal;
        padding: 30px 0;
        @media (max-width:$medium-media-query-size) {
            padding-left: 20px;
            padding-right: 20px;
        }
    
        &-container {
            max-width: $max-width;
            margin: 0 auto;

            h2 {
                color: #fff;
            }
        }

    }
}

.recipes-details-inner-steps {
    display: flex;
    @media (max-width:$medium-media-query-size) {
        flex-direction: column;
    }
    @media (min-width:$medium-media-query-size) {

    > * {
        + * {
            margin-left: 40px;
        }
    }
}
@media (max-width:$medium-media-query-size) {
    > * {
        margin-bottom: 20px;
        width: 100% !important;
    }
}

    h3 {
        color: $green-dark;
    }
    p {
        color: #fff;
    }

    ol {
        color: #fff;
        
        margin-bottom: 20px;
        list-style: none;
        counter-reset: my-awesome-counter;
      }
      ol li {
        counter-increment: my-awesome-counter;
        font-size: 16px;
        line-height: 16px;
        margin-left: 20px;
        line-height: 22px;
        color: #fff;
        position: relative;
        span {
            color: #fff;
        }

        + li {
            margin-top: 10px;
        }

        + li {
            margin-top: 10px;
        }
      }
      ol li::before {
        content: counter(my-awesome-counter) ". ";
        color: $green-dark;
        font-weight: $bold;
        position: absolute;
        left: -20px;
      }
      
    .ingredients {
        width: calc(50% - 145px);
        flex-grow: 1;

        ul {
            li {
                font-size: 16px;
                line-height: 16px;
                margin-left: 20px;
                list-style: disc;
                color: $green-dark;
                line-height: 22px;

                span {
                    color: #fff;
                }

                + li {
                    margin-top: 10px;
                }
            }
        }
    }

    .cookware {
        width:190px;
        flex-shrink: 0;

        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media (max-width:$medium-media-query-size) {
                justify-content: flex-start;
            }

            &-item {
                width: 85px;
                height: 85px;
                background: #fff;
                border-radius: 85px;
                margin-bottom: 20px;
                padding: 10px;
                @media (max-width:$medium-media-query-size) {
                    margin-right: 10px;
                    width: 65px;
                    height: 65px;
                    svg {
                        width: 45px !important;
                    height: 45px !important; 
                    }
    

                }
    
                svg {
                    width: 65px;
                    height: 65px; 
                }
            }
        }
    }

    .instructions {

        width: calc(50% - 145px);
        flex-grow: 1;
    }

    .print {
        max-width: 100px;
        position: relative;
        svg {
            width: 80px;
            height: 100px;
            fill: $green-dark;
        }

        .arrow-icon {
            position: absolute;
            fill: #fff;
            width: 36px;
            height: 54px;
            left: 50px;
            top: 20px;
            transform: rotate(130deg);
                }
    }
}

.more-recipes {
    @media (max-width:$medium-media-query-size) {
        padding-left: 20px;
        padding-right: 20px;
    }

    background: #fff;
    padding: 60px 0;

        &-inner {
            max-width: $max-width;
            margin: 0 auto;
            text-align: center;

            h2 {
                color: $green-dark;
            }
        }

        .more-recipes-list {
            display: flex;
            justify-content: center;
            @media (max-width:$small-media-query-size) {
               flex-direction: column;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                border-radius: 20px;
                height: 300px;
                width: 300px;
                transform: scale(1);
                margin-bottom: 20px;
                background-size: cover;
                overflow: hidden;
                + a {
                    margin-left: 40px;
                    @media (max-width:$small-media-query-size) {
                        margin-left: 0;
                     }
                }

                @media (max-width:$medium-media-query-size) {
                    width:200px;
                    height: 200px;
                }
                @media (max-width:$small-media-query-size) {
                    width: 100%;
                 }

                .name {
                    width: 100%;
                    background: rgba($green-light, 0.8);
                    color: #fff;
                    font-weight: bold;
                    font-size: 1.25rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px;
                    text-align: center;
                }

                &:hover {
                    text-decoration: none;

                transform: scale(1.1);
                }
            }
        }
}

.share-this {
    @media (max-width:$medium-media-query-size) {
        padding-left: 20px;
        padding-right: 20px;
    }

    background: rgba($teal-light, 0.5);
    padding: 60px 0;

        &-inner {
            max-width: $max-width;
            margin: 0 auto;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;

            h2 {
                color: $green-dark;
            }
        }


}