header {
  z-index: 999;
  width: 100%;
  position: fixed;
  background:rgba(#fff, 0.8);
  padding: 10px 40px;
  top: 0;
  left: 0;
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:$medium-media-query-size) {
      flex-wrap: wrap;
      padding: 0 !important;
    }
  }

  @media (max-width:$medium-media-query-size) {
    padding: 5px 20px;
    background:rgba(#fff, 0.9);

  }

}

.main-menu-item .active {
  &:after {
    width: 100%;
  }
}

/* LOGO STYLING */
.header-logo {
  height: 80px;
  width: 80px;
  position: relative;

  @media (max-width:$medium-media-query-size) {
    margin: 10px;
    height: 60px;
    width: 60px;
  
  }
  a {
    display: block;
    height: 80px;
    width: 80px;

    &:hover svg {
          transform: scale(1.1);
          fill: $green-light;
    }
    @media (max-width:$medium-media-query-size) {
      height: 60px;
      width: 60px;
    
    }
  
  }

  svg {
    height: 100%;
    width: 100%;
    transform: scale(1);
    @include transition(.4s);
    fill: $green-dark;
  }
}

/* MOBILE TRIGGER */
.header-mobile-trigger {
  display: none;

  @media (max-width:$medium-media-query-size) {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
    @include transition(.4s);

    svg {
      fill: $green-dark;
      height: 100%;
      width: 100%;
      @include transition(.4s);
    }

    span {
      font-size: .9rem;
      margin-right: 10px;
    }

    &.is-active {
      svg {
        fill: $gray;
      }
    }

  }

}

/* HEADER NAVIGATION */
.header-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include transition(.4s);

  @media (max-width:$medium-media-query-size) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    max-height: 0;
    overflow: hidden;

    &.is-active {
      max-height: 1000px;
    }
  }

  .main-menu-item {
    @media (min-width:$medium-media-query-size) {
        margin-left: 30px;
      position: relative;

    }

    @media (max-width:$medium-media-query-size) {
      flex-grow: 1;
      width: 100%;
      padding: 20px;
      text-align: center;
      border-top: 1px solid darken($gray-light, 5%);
      position: relative;
      z-index: 10;
    }

    @media (min-width:$medium-media-query-size) {
      
      &.active, &:hover {
        > a {
          text-decoration: none;
          color: $green-light;
          transform: scale(1.2);
  
        }
     }
    }

  }
  .main-menu-item > a {
    color: $green-dark;
    font-weight: $bold; 
    @include transition(.2s);
    padding: 40px 0;
    transform: scale(1);
    text-transform: uppercase;

 


    @media (max-width:$medium-media-query-size) {
      font-size: 0.85rem;
    }




  }

}
@media (min-width:$medium-media-query-size) {

.main-menu-item-sub {
  display: none;
    position: absolute;
    top: 40px;
    left: -20px;
    padding: 20px;
    background: rgba($green-dark, 0.9);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    
    > a {
      margin-left: 0;
      color: #fff;
      font-weight: $bold; 
      text-transform: uppercase;
  
      + a {
        margin-top: 10px;
      }
    }
}

.main-menu-item {
  &:hover {
    .main-menu-item-sub {
      display: block;
    }
  }
}
}

.main-menu-item-sub {
@media (max-width:$medium-media-query-size) {
  flex-grow: 1;
  width: 100%;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  position: relative;
  z-index: 10;
  border: 1px dashed $green-dark;
  a {
    color: $green-dark;
    font-weight: $bold; 
    @include transition(.8s);
    transform: scale(1);
    text-transform: uppercase;

 


    @media (max-width:$medium-media-query-size) {
      font-size: 0.85rem;
    }

  }
}
}