.recipe-list {
    &-title {
        color: $teal;
        @media (max-width:$medium-media-query-size) {
            display: none;
        }

    }

    &-text {
        margin: 30px 0;
        h1 {
            color: $teal;
            margin-bottom: 0;
        }
        p {
            color: $green-dark;
            margin-bottom: 0;

        }
    }


}

.recipe-list-container {
    margin-bottom: 30px;

    &-boxes {
        display: flex;
        flex-wrap: wrap;

        a {
            width: calc(25% - 22.5px);
            margin-bottom: 30px;
            overflow: hidden;
            border-radius: 10px;
            display: flex;
            transform: scale(1);
            flex-direction: column;

            @media (min-width:$medium-media-query-size) {

                margin-right: 22.5px;

            }

            @media (max-width:$medium-media-query-size) {
                width: calc(33.333% - 20px);

                + a {
                    margin-left: 30px;
                }

                &:nth-child(3n+4) {
                    margin-left: 0;
                }
            }
            
            @media (max-width:$small-media-query-size) {
                width: calc(50% - 15px);

                &:nth-child(even) {
                    margin-left: 30px !important;
                }

                &:nth-child(odd) {
                    margin-left: 0 !important;
                }

            }

            &:hover {
                text-decoration: none;
                transform: scale(1.05);

            }

            .box-text {
                padding: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fff;
                flex-grow: 1;

                .title {
                    font-size: 1.5rem;
                    font-weight: $bold;
                    margin: 0;
                    text-align: center;

                }

                .subtitle {
                    margin: 0;
                }
            }
        }
    }

    .breakfast {
            .box-text {
                background: $green-light;
            }
    
    }
    .appetizer {
            .box-text {
                background: $orange;
            }
    }
    .side-dish {
            .box-text {
                background: $green-dark;
            }
    }
    .dinner {
            .box-text {
                background: $teal;
            }
    }
}

.recipe-list-filter {
    display: flex;
    background-image: linear-gradient(to right, $green-light , $teal);
    padding: 10px;
    border-radius: 10px;
    align-items: center;

    @media (max-width:$medium-media-query-size) {
        flex-direction: column;

    }

    .filter-title {
        color: #fff;
        font-family: $font-heading;
        font-size: 3rem;
        @media (max-width:$medium-media-query-size) {
            margin-bottom: 20px;
            text-align: center;
            font-size: 2rem;

        }
    }

    .filter-options {
        display: flex;
        align-items: center;
    @media (max-width:$medium-media-query-size) {
        width: 100%;
    }
        &-item {
            display: flex;
            align-items: center;
            margin-left: 30px;
            flex-grow: 1;
            @media (max-width:$medium-media-query-size) {
                flex-direction: column;
                margin: 0 5px 20px 5px !important;
                width: 33.33%;

            }
        
            .label {
                margin-right: 10px;
                font-weight: $bold;
                color: $green-dark;
                text-transform: uppercase;
                font-size: 1rem;
            }

            select {
                @media (max-width:$medium-media-query-size) {
                    width: 100%;
                }
                max-width: 180px;
    overflow: hidden;
                  /* styling */
  display: inline-block;
  line-height: 1.5em;
  border: 1px solid $green-dark;
  padding: 0.5em 2em 0.5em 0.5rem;
background: none;
font-size: 1rem;
color: #fff;
  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, $green-dark 50%),
    linear-gradient(135deg, $green-dark 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
            }
        }
    }
}