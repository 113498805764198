


.btn-primary, .btn {
  display: inline-block;
  padding:5px 10px;
  color: #fff;
  position: relative;
  font-size: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-heading;
  line-height: auto;
  font-weight: $normal;
  background: $teal;
  text-transform: uppercase;
  border-radius: 20px;
  outline: none;

  &:hover {
    background: darken($teal, 10%);
  }
}



.btn-secondary {
  display: inline-block;
  padding:5px 10px;
  color: #fff;
  position: relative;
  font-size: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-heading;
  line-height: auto;
  font-weight: $normal;
  background: $orange;
  text-transform: uppercase;
  border-radius: 20px;
  outline: none;

  &:hover {
    background: darken($orange, 10%);
  }
}


