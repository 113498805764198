.product-page {
    background: $teal;
    margin-top: -110px; //offset for header
    padding: 140px 0 60px;

    &-inner {
        max-width: $max-width;
        margin: 0 auto;

    }

    @media (max-width:$medium-media-query-size) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.product-page-title {
    display: flex;
    align-items: center;

    h1 {
        color: #fff;
    }

    .certified-logos {
        margin-left: 20px;

        svg {
            width: 80px;
            width: 80px;
            + svg {
                margin-left: 10px;
            }
        }
    }
}

.product-page-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width:$medium-media-query-size) {
        flex-wrap: wrap; 
        justify-content: flex-start;

    }
    li {
        @media (max-width:$medium-media-query-size) {
            max-width: 60px;
            margin: 10px !important;
            
        }
        margin: 0;

        + li {
            margin-left: 20px;
        }

        a {
            display: block;
            transform: scale(1);
            @include transition(.4s);
            
            &:hover, &.active {
                transform: scale(1.1);
            }
        }
    }

    img {
        &.shadow {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        }
     }
}

.product-page-details {
    display: flex;
    padding: 120px 0 60px 0;

    @media (max-width:$medium-media-query-size) {
        flex-direction: column;
    }    

    &-main {
        max-width: 60%;
        flex-grow: 1;
        display: flex;

        @media (max-width:$medium-media-query-size) {
            max-width: 100%;
        }

        @media (max-width:$small-media-query-size) {
            flex-direction: column;

            > * {
                width: 100%;
            }

        }


        &-img {
            width: calc(55% - 10px);
            position: relative;
            @media (max-width:$medium-media-query-size) {
                height: 700px;
            }

            @media (max-width:$small-media-query-size) {
                width: 100%;
                height: unset;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 40px;
            }

            .dish-img {
                @media (max-width:$small-media-query-size) {
                    max-width: 450px;
                    width: 100%;
                }
            }

            .package-img {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                position: absolute;
                max-width: 180px;
                top: 300px;
                right: 0;

                @media (max-width:$small-media-query-size) {
                position: relative;
                top: unset;
                margin-top: -60px;
                }

            }
        }

        &-text {
            margin: 0 20px;
            color: #fff;
            width: calc(45% - 10px);
            position: relative;

            @media (max-width:$small-media-query-size) {
                width: 100%;
                margin: 0;
            }

            .product-ingredient-extra {
                position: absolute;
                top: -120px;
                left: 60%;
                width: 400px;
                flex-direction: row-reverse;
                display: flex;
                align-items: center;

                @media (max-width:$medium-media-query-size) {
                    left: -50%;
                    width: 150%;
                }

                @media (max-width:$small-media-query-size) {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    justify-content: center;
                                }

                &-text {
                    margin-left: 80px;
                    position: relative;

                    svg {
                        position: absolute;
                        fill: #fff;
                        width: 36px;
                        height: 54px;
                        left: -55px;
                        top: 18px;
                        transform: rotate(240deg);       
                    }   
                }

                &-img {
                    max-width: 130px;
                }
            }

            .product-name {
                font-size: 2.25rem;
                line-height: 2.75rem;
                margin-bottom: 5px;

            }

            .product-subname {
                font-size: 1.55rem;
                line-height: 2.25rem;
                margin-bottom: 5px;
            }

            .product-text {
                font-size: 1.125rem;
                line-height: 1.5rem;
                padding-bottom: 20px;
                margin-bottom: 0;
                border-bottom: 1px solid #fff;
                @media (max-width:$medium-media-query-size) {
                    border-bottom: 0;
                }
            }

            .chef-tip {
                position: relative;

                @media (max-width:$medium-media-query-size) {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                }
                @media (max-width:$small-media-query-size) {
                padding-bottom: 220px;
                }

                &-inner {
                    position: absolute;
                    bottom: 20px;
                    right: 10px;
                    z-index: 80;
                    @media (max-width:$medium-media-query-size) {
                        position: relative;
                        width: 140px;
                    }
                }


                &-circle {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 140px;
                    height: 140px;
                    background: #fff;
                    border-radius: 140px;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;    
                    padding: 10px;
                    .title {
                        color: $teal;
                        font-family: $font-heading;
                        font-size: 2rem;

                    }

                    .text {
                        color: $green-dark;
                        font-size: 0.85rem;
                    }
                    
                    &:after {
                        content: '';
                        position: absolute;
                        top: -5px;
                        left: -5px;
                        height: calc(100% + 10px);
                        width: calc(100% + 10px);
                        border: 3px dashed #fff;
                        border-radius: 140px;
                        background: $teal;
                        z-index: -1;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: -10px;
                        left: -10px;
                        height: calc(100% + 20px);
                        width: calc(100% + 20px);
                        border-radius: 140px;
                        background: $teal;
                        z-index: -2;
                    }
                }

                .spoon-img {
                    position: absolute;
                   transform: rotate(145deg);
                   max-width: unset;
                   width: 250px;
                   right: 0;
                   bottom: -260px;
                }
            }
        }
    }
}

.product-page-details-nutrition {
    &-ingredients {
        color: #fff;
        padding-left: 20px;
        border-left: 1px solid #fff;
        margin-bottom: 20px;

        h4 {
            position: relative;

            svg {
                position: absolute;
                fill: #fff;
                width: 36px;
                height: 54px;
                left: 20px;
                top: -60px;
            }
        }

        @media (max-width:$medium-media-query-size) {
            padding-left: 0;
            border-left: 0;
        }

        img {
            margin-bottom: 20px;
            width: 70%;
            margin-left: 15%;
        }

        h4 {
            margin-bottom: 5px;
        }

        p {
            font-size: 0.85rem;
            line-height: 1rem;
        }
    }
}

.product-page-details-nutrition-misc {
    padding-left: 20px;
    display: flex;
    color: #fff;

    @media (max-width:$medium-media-query-size) {
    padding-left: 0;

    }
    
    @media (max-width:$small-media-query-size) {
    flex-direction: column;
    }

    @media (min-width:$small-media-query-size) {
        
    > * {

        + * {
            margin-left: 20px;
        }
    }
}

    .cooking-steps {
        width: calc(40% - 10px);

        @media (max-width:$small-media-query-size) {
            width: 100%;
            margin-bottom: 20px;
        }

        h4 {
            margin-bottom: 5px;
        }

        .subtitle {
            font-size: 0.75rem;
            line-height: 1rem;
            margin-bottom: 10px;

        }

        .cooking-steps-list-item {
            margin-top: 20px;

            .title {
                font-family: $font-heading;
                font-size: 1.5rem;
                margin-bottom: 10px;
            }

            .text, .text p {
                font-size: 0.85rem;
                line-height: 1.25;
            }
        }

    }
}

.nutrition-facts {
    width: calc(60% - 10px);

    padding-left: 20px;
    border-left: 1px solid #fff;

    @media (max-width:$small-media-query-size) {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0;
        border-left: 0;
    }


    h4 {
        margin-bottom: 10px;
    }

    .serving-size {
        font-size: 0.75rem;
        line-height: 1rem;
        margin-bottom: 05px;
        padding-top: 5px;
        margin-top: 5px;
        border-top: 1px solid #fff;
    }

    .serving-size-table {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 5px solid #fff;

        > div {
            font-family: $font-regular;
            font-size: 1rem;

        }
    }

    .nutrition-table {
        border-bottom: 5px solid #fff;
        padding-bottom: 5px;
        margin-bottom: 5px;

        &-row {
            display: flex;

            > * {
                width: 33.333%;
                padding-top: 4px;
                padding-bottom: 4px;
                border-bottom: 1px solid #fff;

                + * {
                    margin-left: 4px;
                    padding-left: 4px;
                    border-left: 1px solid #fff;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 3px;
                        background: $teal;
                        bottom: -3px;
                        left: 0;
                    }
                
                }
            }

            .text {
                font-size: .55rem;

                &-right {
                    text-align: right;
                }
            }

            &.regular-row, &.indetend-row {
                font-size: .65rem;

                .col-flex {
                    display: flex;
                    justify-content: space-between;
                }

            }

            &.regular-row {
                > * {
                    &:first-child {
                        font-weight: $bold;
                    }
                }

            }

            &.indetend-row {
                > * {
                    &:first-child {
                        &:before {
                            content: '';
    width: 2px;
    height: 1px;
    display: inline-block;                        }
                    }
                }
            }
        }

        &-header {
            .title {
                font-family: $font-regular;
                font-size: 1rem;
                
            }

            .text {
                font-size: .55rem;
            }

            > * {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                
                border-bottom: 3px solid #fff;

                + * {
                    align-items: flex-end;
                }
            }
        }
    }
}

.nutrition-disclaimer {
    font-size: .55rem;

}