// ------------- GLOBAL FORM ELEMENT STYLES ------------- //

textarea {
    outline: 0;
    resize: none;
    border: 0;
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel] {
    outline: 0;
    border: 0;

    &::-webkit-input-placeholder {

    }

    &::-moz-placeholder {

    }

    &:-ms-input-placeholder {

    }
}

select {

}

label {

}

fieldset {

}


.grecaptcha-badge {
	display: none;
}