.blog-single {
    h1 {
        color: $green-dark;
    }

    .blog-img-main {
        margin: 30px 0;

        img {
            max-width: 80%;
            margin: 0 10%;
        }
    }


    .blog-text {
        color: $green-dark;
    }
}