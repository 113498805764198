.blog-list {
    h1 {
        color: $green-dark;
    }
}

.blog-list-container {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;

    &-item {
        width: calc(33.333% - 20px);
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position: relative;
        transform: scale(1);

        @media (min-width:$medium-media-query-size) {

            &:nth-child(3n+2){
                margin-left: 30px;
                margin-right: 30px;
                margin-top: -20px;
                margin-bottom: 50px;
            }
        }

        @media (max-width:$medium-media-query-size) {
            width: calc(50% - 15px);

            &:nth-child(even){
                margin-left: 30px;
                margin-top: -20px;
                margin-bottom: 50px;
            }

        }

        @media (max-width:$small-media-query-size) {
            width: 100%;
            margin-left: 0 !important;
            margin-top: 0 !important;
            margin-bottom: 30px !important;
        }

        .blog-content {
            padding: 20px;
            background: #fff;

            .blog-title {
                color: $teal;
                margin-bottom: 10px;
            }

            .blog-text {
                color: $green-dark;
                margin-bottom: 0;

            }
        }

        .blog-category {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 5px;
            background: rgba($teal, 0.9);
            border-radius: 5px;
            color: #fff;
            font-family: $font-heading;
            font-size: 1.5rem;
        }

        &:hover {
            text-decoration: none;
            transform: scale(1.03);
        }
    }

    

}