// ------------- GENERAL TAGS ------------- //



body {
    font-weight: $normal;
    font-family: $font-regular;
    font-size: 16px;
    color: $gray-dark;
    height: 100%;
    width: 100%;

    @media (max-width:$medium-media-query-size){
      font-size: 14px;
    }

    @media (max-width:$small-media-query-size){
      font-size: 12px;
    }

    ::selection {

    }

    ::-moz-selection {

    }

    .regular-font {
      font-family: 'CutieCaps' !important;
    }
}

a {
    text-decoration: none;
    @include transition(.2s);

    &:hover {
      text-decoration: none;

    }
}


h1 {
  font-size: 5.125rem;
  line-height: 5.5rem;
  font-family: $font-heading;
  font-weight: $normal;
  margin-bottom: 1rem;
  text-transform: uppercase;

  @media (max-width:$medium-media-query-size) {
    font-size: 3.25rem;
    line-height: 4.5rem;
  }

}

.teal-font {
  color: $teal;
}

h2 {
  font-size: 3.125rem;
  line-height: 4.5rem;
  font-family: $font-heading;
  font-weight: $normal;
  margin-bottom: 1rem;
  text-transform: uppercase;
  
  @media (max-width:$medium-media-query-size) {
    font-size: 2.75rem;
    line-height: 3.5rem;
  }


}

h3 {
  font-size: 2.25rem;
  line-height: 2.7rem;
  font-family: $font-heading;
  font-weight: $normal;
  margin-bottom: 1rem;
  text-transform: uppercase;
  
  @media (max-width:$medium-media-query-size) {
    font-size: 1.75rem;
    line-height: 2.25rem;
      }

}

h4 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-family: $font-heading;
  font-weight: $normal;
  margin-bottom: 1rem;
  text-transform: uppercase;
  
  @media (max-width:$medium-media-query-size) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}


p {
  font-size: 1rem;
  line-height: 1.75rem;
  font-family: $font-regular;
  font-weight: $normal;
  margin-bottom: 1rem;

  a {
    color: $green;
    position: relative;
    @include transition(.2s);
    text-decoration: none;
    z-index: 90;


    &:after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      @include transition(.2s);
      background: $green;
      z-index: -1;
      width: 100%;
      cursor: pointer;
    }

    &:hover {
      color: #fff;
      text-decoration: none;

      &:after {
        height:  calc(100% + 2px);
      }
    }
  }

}

ul {
  font-size: 1rem;
  line-height: 1.75rem;
  font-family: $font-regular;
  font-weight: $normal;
  margin-bottom: 1rem;

  li {
    + li {
      margin-top: .5rem;
    }
  }
}


// ------------- MOBILE SIZING ------------- //

@media (max-width:$small-media-query-size) {
    h1 {

    }

    h2 {

    }

	h3 {

	}

	h4 {

	}
}
