.home {
    background: url('../imgs/bg-pattern-white.png');
    margin-top: -110px; //offset for header
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width:$medium-media-query-size) {
        height: unset!important;
        padding: 200px 0;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: $green-light;
      z-index: -1;
    }

    &-bowl {
        position: absolute;
        z-index: 20;
        &-1 {
            width: 20%;
            right: 10%;
            top: -5%;
            
        }

        &-2 {
            width: 25%;
            right: 5%;
            bottom: -15%;

            @media (max-width:$medium-media-query-size) {
    width: 30%;
    right: -5%;
    bottom: -5%;            }
        }

        &-3 {
            width: 30%;
            left: -10%;
            top: 20%;
            @media (max-width:$medium-media-query-size) {
                width: 40%;
                left: -15%;
                top: 20%;          }
            
        }
    }

    &-veg {
        position: absolute;
        z-index: 30;
        &-1 {
            width: 15%;
            right: 20%;
            top: 10%;
        
        }    

        &-2 {
            width: 20%;
            left: 15%;
            top: 5%;        
        }
        &-3 {
            width: 20%;
            left: 15%;
            bottom: -10%;        
        }
    }

    &-bottom {
        height: 0;
        padding-top: 87.26px / 1365.42px * 100%;
        position: absolute;
        bottom: 200px;
        left: 0;
        width: 100%;

           &:before {
               position: absolute;
                width: 100%;
                height: 200px;
                background: $teal;
                content: '';
            } 

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            fill: $teal;
        }
    }

    &-tag {
        height: 0;
        padding-top: 411.5px / 1318px * 100%;
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
        z-index: 10;
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            fill: #fff;
            opacity: .3;

        }
    }

    &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 50;
        position: relative;
        max-width: 80%;

        @media (max-width:$medium-media-query-size) {
            flex-direction: column;

            > * {
                margin-bottom: 20px;
            }
        }

        svg {
            fill: $green-dark;
            width: 350px;
            height: 350px;
            margin-right: 50px;

            @media (max-width:$medium-media-query-size) {
                margin-right: 0;
                max-width: 60%;
                height: 60%;
                        }
          
        }

        &-text {
            color: #fff;

            h1 {
                > span {
                    font-family: 'CutieCaps';                }
            }

            p {
                font-size: 1.5rem;
                line-height: 3rem;
            }
        }
    }
}