.product-list {
    &-title {
        color: $teal;

    }



}

.product-list-container {
    margin: 30px 0;

    &-boxes {
        display: flex;
        flex-wrap: wrap;

        a {
            width: 33.333%;
            margin-bottom: 30px;
            overflow: hidden;
            border-radius: 10px;
            display: flex;
            transform: scale(1);
            flex-direction: column;

            @media (min-width:$medium-media-query-size) {
                padding: 0 60px;
                margin-bottom: 60px;

            }

            @media (max-width:$medium-media-query-size) {
                padding: 0 20px;
                margin-bottom: 40px;
                width: 50%;

            }
        

            &:hover {
                text-decoration: none;
                transform: scale(1.05);

            }

            .box-text {
                padding: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-grow: 1;

                .title {
                    font-size: 2.5rem;
                    font-family: $font-heading;
                    font-weight: $normal;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    text-align: center;
                    color: $green-dark;
                }

                .subtitle {
                    font-size: 1rem;
                    margin: 0;
                    text-align: center;
                    color: $gray;

                }

            }
        }
    }

}


.product-list-title {
    display: flex;
    align-items: center;

    h1 {
        color: $teal;
    }

    .certified-logos {
        margin-left: 20px;

        svg {
            width: 80px;
            width: 80px;
            + svg {
                margin-left: 10px;
            }
        }
    }
}

.product-page-list {
    &-mobile {
        display: none !important;
        margin: 0 20px;
        width: calc(100% - 40px);

        @media (max-width:$small-media-query-size) {
            display: block !important;

        }

    }

    &-desktop {
        @media (max-width:$small-media-query-size) {
            display: none;

        }
    }
}