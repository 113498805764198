html {
    scroll-behavior: smooth;

}

.ingredients-header-wrapper {
    height: 615px;

    @media (max-width:$medium-media-query-size) {
        height: 435px;
    }

}
.ingredients-header {
    margin-top: 5px;
    @media (max-width:$medium-media-query-size) {
        margin-top: -20px;
    }


    &-wrapper {

    }

    .ingredients-header-top, .ingredients-header-bottom {
        height: 0;
        padding-top: 87.26px / 1365.42px * 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            fill: $teal;
        }
    }

    .ingredients-header-bottom {
        transform: rotate(180deg);
        margin-top: -2px;
    }

    nav {
        position: relative;
        padding-top: 60px;
        h1 {
            position: absolute;
            opacity: 0.4;
            color: #fff;
            top: 0;
            bottom: 0;
            left: -30px;
            margin: auto;
            transform: rotate(270deg);
            letter-spacing: 10px;

            @media (max-width:$medium-media-query-size) {
                display: none;
            }
        }
    }
}

.ingredients-header-nav {
    background: $teal;

    nav {
        max-width: $max-width;
        margin: 0 auto;    
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .ingredients-anchor-link {
            width: 150px;
            margin: 25px;
            position: relative;

            @media (max-width:$medium-media-query-size) {
                width: 100px;
            }

            @media (min-width:$medium-media-query-size) {
                &:hover {
                    .ingredients-anchor-link-hover {
                        display: block;
                    }

                }
            }

            img {
                transform: rotate(0);
                @include transition(.2s);

            }
            
            &-hover {
                
                display: none;
                position: absolute;
                top: -50px;
                left: -10px;

                @media (max-width:$medium-media-query-size) {
                    display: block;
                    top: -30px;
                    left: 0;
                    width: 100%;
                    text-align: center;
                }
    
                h4 {
                    color: #fff;
                }

                svg {
                    position: absolute;
                    fill: #fff;
                    width: 36px;
                    height: 54px;
                    left: 90px;
                    top: 8px;
                    transform: rotate(105deg);

                    @media (max-width:$medium-media-query-size) {
                        display: none;
                    }
    
                }   

            }
        }
    }
}

.ingredients-list {
    max-width: $max-width;
    margin: 0 auto 150px auto;    

    &-item {

        &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width:$medium-media-query-size) {

                flex-direction: column;
            }
    
        }

        @media (max-width:$medium-media-query-size) {
            margin: 100px 25px;

            img {
                max-width: 400px;
            }
        }

        @media (min-width:$medium-media-query-size) {
            margin-bottom: -100px;

            + .ingredients-list-item {

                &:before {
                    content: '';
                    position: relative;
                    height: 100px;
                    width: 100%;
                    display: block;
                }
            }

            &:nth-child(odd) {
                .ingredients-list-item-inner {
                    flex-direction: row-reverse;
                }
            }
        }

        .ingredients-list-item-content {
            @media (min-width:$medium-media-query-size) {

                width: 65%;
                margin: 50px 50px;
            }

        }

        img {
            @media (min-width:$medium-media-query-size) {

                width: 35%;
                margin: 50px 50px;
            }

            @media (max-width:$medium-media-query-size) {

                width: 45%;
            }


        }

        &-content {
            color: $green-dark;
            text-align: center;

            h2 {
                color: $teal;
            }
        }
    }
}


.ingredients-header.fixed {
    nav {
        padding-top: 0;
    }        

    @media (min-width:$medium-media-query-size) {
        position: fixed;
        width: 100%;
        z-index: 999;
        top: 95px;
        opacity: 0.9;
        
        .ingredients-header-top, .ingredients-header-bottom {
            display: none;
        }

        .ingredients-header-nav {
            h1 {
                display: none;
            }

            nav {
                flex-wrap: nowrap;

                .ingredients-anchor-link-hover {
                    display: none !important;
                }

                .ingredients-anchor-link {
                    width: 75px;
                    margin: 10px 20px;
                }
            }
        }
    }
}