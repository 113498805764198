@font-face {
    font-family: 'HappyFox-Condensed';
    src: url('../fonts/HappyFox-Condensed.eot');
    src: url('../fonts/HappyFox-Condensed.woff2') format('woff2'),
         url('../fonts/HappyFox-Condensed.woff') format('woff'),
         url('../fonts/HappyFox-Condensed.ttf') format('truetype'),
         url('../fonts/HappyFox-Condensed.svg#HappyFox-Condensed') format('svg'),
         url('../fonts/HappyFox-Condensed.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
     font-family: 'CutieCaps';
     src: url('../fonts/cutiescaps_free-webfont.woff2') format('woff2'),
          url('../fonts/cutiescaps_free-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
   }
