footer {

}
.widgetizedArea {
  background: url('../imgs/bg-pattern-white.png');
  padding: 60px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: $teal-light;
    z-index: -1;
  }

  h2 {
    color: $green-dark;
  }

  form {
    background: #fff;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition(.2s);

    &:hover, &:focus {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    }

    p {
      margin: 0;

      &:first-child {
        flex-grow: 1;
      }


    }

    label {
      display: none !important;
    }

    input {
      flex-grow: 1;
      min-width: 300px;
      font-size: 1rem;
      padding-left: 10px;
      @media (max-width:$medium-media-query-size) {
        min-width: 200px;
      }

    }

    input[type="submit"] {
      @extend .btn;
      min-width: 100px;
    }
   }
  

}
.store-locator {
  background: url('../imgs/bg-pattern-white.png');
  padding: 60px 0;
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: $teal-light;
    z-index: -1;
  }

  &-inner {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: $green-dark;
    }

    &-form {
      background: #fff;
      border-radius: 20px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        flex-grow: 1;
        min-width: 300px;
        font-size: 1rem;
        padding-left: 10px;
        @media (max-width:$medium-media-query-size) {
          min-width: 200px;
        }

      }

     }
  }
}

.footer-main {
  padding: 60px 0;
  background: $green-dark;

  @media (max-width:$medium-media-query-size) {
    padding: 30px;
  }

  * {
    color: #fff;
  }

  &-inner {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;

    @media (max-width:$medium-media-query-size) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }


    &-col {
      flex-grow: 1;
      padding: 10px 40px;

      @media (max-width:$medium-media-query-size) {
        width:100%;
        padding: 20px;

        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

      }

      + * {
        margin-left: 40px;
        border-left: 1px solid $green-light;

        @media (max-width:$medium-media-query-size) {
            border-left: none;
            border-top: 1px solid $green-light;
            margin-left: 0;
            margin-top: 20px;
        }
      }
    }

  }

  .pol-logo {
    width: 110px;
    height: 110px;
    fill: #fff;
  }
}

.footer-menu-social {
  display: flex;

  li {
    a {
      display: block;
      width:30px;
      height: 30px;
      padding: 5px;
      border-radius: 20px;
      background: $green-light;
      transform: scale(1);

      &:hover  {
        transform: scale(1.1);
      }

      svg {
        fill: #fff;
        height: 20px;
        width: 20px;
      }
    }

    + li {
      margin: 0 0 0 10px;

    }
  }
}


.newsletter-signup {
  #tblFormLogo1120433, #tblRequiredField1120433 {
    display: none;
    padding: 0;
    font-size: 1rem;
    line-height: 1.75rem;
  }
  .bmform_outer1120433 {
    width: unset !important;

    .bmform_inner1120433 {
      border: 0;

      .bmform_head1120433 {
        display: none;
        padding: 0;
      }

      .bmform_body1120433 {
        background: none;
        padding: 0;
        .bmform_introtxt1120433 {
          display: none;
          padding: 0;
        }

        #tblFieldData1120433 {
          .bmform_frmtext1120433, .bmform_frm1120433 {
            padding: 0;
            
          }

          .bmform_frmtext1120433 {
            font-size: 1rem;
            line-height: 1.75rem;
            margin-bottom: 5px;
            font-weight: $normal;
            font-family: $font-regular;

            &:nth-child(3), &:nth-child(5) {
              display: none;
            }
          }  

          .bmform_frm1120433 {
            padding: 5px;
            margin-bottom: 5px;
            border-radius: 5px;

            &:nth-child(6), &:nth-child(4) {
              display: none;
            }
          }  


        }
      }

      .bmform_footer1120433 {
        display:none;
      }

    }
  }
  .bmform_button1120433 {
    text-align: left;
  }
  #btnSubmit {
    @extend .btn-primary;
    outline: none;
    border: 0;
  }
}
